import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import IconBaseProps from "./IconBaseProps";

const IconBase = loadable(
    (): Promise<DefaultComponent<IconBaseProps>> =>
        import(/* webpackChunkName: "IconBase" */ "./IconBase"),
);
const IconBaseLoader = (props: IconBaseProps): ReactElement => (
    <IconBase {...props} />
);
export default IconBaseLoader;

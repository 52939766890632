import { styled } from "styled-components";

import IconBase from "components/IconBase";

export const Logo = styled.img`
    height: 3rem;
    width: auto;
`;

export const StyledIcon = styled(IconBase)`
    vertical-align: middle;
`;
